import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { HomeScreen } from "./screens/HomeScreen";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import { SupportScreen } from "./screens/SupportScreen";
import TermsAndConditions from "./screens/TermsOfService";
import { TestimonialsScreen } from "./screens/TestimonialsScreen";
import { Footer, Header } from "./components/navComponents";
import { LoginScreen } from "./screens/LoginScreen";
import { AdminScreen } from "./screens/AdminScreen";
import { UserReportsScreen } from "./screens/UserReportsScreen";
import { Helmet } from "react-helmet";
import { NotFoundScreen } from "./screens/NotFoundScreen";
import { DetectScreen } from "./screens/DetectScreen";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZX1iYp7U8zhCukP9hVuFVzBWJ2gfvM94",
  authDomain: "face2face-trent.firebaseapp.com",
  projectId: "face2face-trent",
  storageBucket: "face2face-trent.appspot.com",
  messagingSenderId: "626620486094",
  appId: "1:626620486094:web:69666f491cb24e37cddb9f",
  measurementId: "G-C1164NDK3P",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

interface LayoutProps {
  children: React.ReactNode;
}

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  ...props
}) => {
  const auth = getAuth(firebaseApp);

  if (!auth.currentUser) {
    alert("Must be logged in to view page");
  }
  return auth.currentUser ? element : <Navigate to="/login" />;
};

const Layout = ({ children }: LayoutProps) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<NotFoundScreen />} />
      <Route
        path="/"
        element={
          <>
            <Helmet>
              <title>{"Download Face2Face | Face2Face"}</title>
            </Helmet>

            <Layout children={<HomeScreen />} />
          </>
        }
      />
      <Route path="/detect"
        element={<>
          <Helmet>
            <title>{"Detect device software"}</title>
          </Helmet>
          <Layout children={<DetectScreen />} />
        </>}
      />

      <Route
        path="/support"
        element={
          <>
            <Helmet>
              <title>{"Support | Face2Face"}</title>
            </Helmet>
            <Layout children={<SupportScreen />} />
          </>
        }
      />
      <Route
        path="/testimonials"
        element={
          <>
            <Helmet>
              <title>Testimonials | Face2Face</title>
            </Helmet>
            <Layout children={<TestimonialsScreen />} />
          </>
        }
      />
      <Route
        path="/terms"
        element={
          <>
            <Helmet>
              <title>Terms and Conditions | Face2Face</title>
            </Helmet>
            <Layout>
              <TermsAndConditions />
            </Layout>
          </>
        }
      />
      <Route
        path="/privacy"
        element={
          <>
            <Helmet>
              <title>Privacy Policy | Face2Face</title>
            </Helmet>
            <Layout>
              <PrivacyPolicy />
            </Layout>
          </>
        }
      />
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/admin"
        element={<ProtectedRoute element={<AdminScreen />} />}
      />
      <Route
        path="admin/user/:uid"
        element={<ProtectedRoute element={<UserReportsScreen />} />}
      />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
