import React from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import TextField from "@mui/material/TextField";

import { Button } from "@mui/material";

export const SupportScreen = () => {
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");

  const sendEmail = async () => {
    if (firstName === "" || lastName === "" || comment === "" || email === "") {
      alert("One or more fields are empty please correct that below");
      return;
    }

    try {
      console.log(getFunctions());
      const sendEmailFunction = httpsCallable(getFunctions(), "sendEmail");

      await sendEmailFunction({
        name: `${firstName} ${lastName}`,
        email,
        comment,
      });

      setFirstName("");
      setLastName("");
      setComment("");
      setEmail("");
      window.location.reload()
    } catch (error) {
      console.error("error", error);
      window.location.reload()
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2
          style={{
            alignSelf: "center",
            width: "50%",
            textAlign: "center",
          }}
        >
          Please enter your name and problem below and we will get back to you
          as soon as possible
        </h2>
        <div
          style={{
            height: "auto",
            width: "50%",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            id="firstNameField"
            required
            label={firstName.length ? "" : "First Name"}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            style={{
              alignSelf: "flex-start",
              width: "100%",
              marginBottom: "3%",
            }}
            placeholder="First Name"
          />
          <TextField
            id="lastNameField"
            required
            label={lastName.length ? "" : "Last Name"}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            style={{
              alignSelf: "flex-start",
              width: "100%",
              marginBottom: "3%",
            }}
            placeholder="Last Name"
          />
          <TextField
            id="emailField"
            required
            label={email.length ? "" : "Email associated with account"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            style={{
              alignSelf: "flex-start",
              width: "100%",
              marginBottom: "3%",
            }}
            placeholder="Email associated with account"
          />
          <TextField
            id="commentField"
            required
            label={comment.length ? "" : "Enter your question or concern"}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            minRows={4}
            multiline
            placeholder="Enter your question or concern"
          />
          <Button
            id="submitButton"
            style={{ marginTop: "3%", backgroundColor: "#7851a9" }}
            variant="contained"
            onClick={() => sendEmail()}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};
