import React, { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export const DetectScreen = () => {
    
    React.useEffect(() => {
    const detectDevice = httpsCallable(getFunctions(), 'detectDeviceV2');
        detectDevice()
            .then((result: any) => {
                if (result.data.url) {
                    // Redirect to the appropriate store URL based on the result from Firebase
                    window.location.href = result.data.url;
                } else {
                    alert('Device not recognized or other issue.');
                }
            })
            .catch((error) => {
                console.error("Error detecting device:", error);
            });


    }, []);

    return (
        <div>
            <h1>Detecting your device...</h1>
        </div>
    );
};
